import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import axios, { all } from "axios";
import Chart from "react-apexcharts";
import { Typography } from "@mui/material";

const fetchData = async (setLoaded, setValues) => {
  try {
    var allHousingData = [];
    var categories = {
      2014: 0,
      2015: 0,
      2016: 0,
      2017: 0,
      2018: 0,
      2019: 0,
      2020: 0,
      2021: 0,
      2022: 0,
      2023: 0,
    };

    // console.log(allHousingData);

    for (let i = 1; i <= 9; i++) {
      // console.log(i);
      const response = await axios.get(
        `https://backend.vetsaid.me/api/housing?page[size]=100&page[number]=${i}`,
        {
          headers: {
            Accept: "application/vnd.api+json",
          },
        }
      );
      //   console.log(response.data.data)
      response.data.data.forEach((entry) => {
        // categories.add(Number(entry["attributes"]["complete_data"].substring(0, 4)))
        categories[entry["attributes"]["complete_date"].substring(0, 4)]++;
      });
      allHousingData.push(...response.data.data);
    }
    // console.log(Object.values(categories));
    setValues(Object.values(categories));

    setLoaded(true);
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
};

const AreaChart = () => {
  const [values, setValues] = useState([]);
  const [isLoaded, setLoaded] = useState(false);
  const series = [
    {
      name: "Housing Built",
      data: values,
    },
  ];

  useEffect(() => {
    fetchData(setLoaded, setValues);
    setLoaded(true);
  }, []);
  if (isLoaded) {
    // console.log(housingData);
    // console.log(xaxis);
  }

  return (
    <>
      {isLoaded ? (
        <Box sx={{ textAlign: "center" }}>
          <Typography variant="h5" sx={{ fontWeight: "bold", padding: "10px" }}>
            Housing establishment by date
          </Typography>
          <Box sx={{ width: "50%", margin: "auto " }}>
            <Chart
              options={{
                chart: {
                  id: "basic-bar",
                },
                xaxis: {
                  categories: [
                    2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023,
                  ],
                },
              }}
              series={series}
              type="area"
            />
          </Box>
        </Box>
      ) : (
        <Box>loading</Box>
      )}
    </>
  );
};

export default AreaChart;
