import { Typography } from "@mui/material";
import { CardContentWrapper } from "../../../styles/splashcard";

export default function CardContent({ model, matches }) {
  return (
    <CardContentWrapper>
      <Typography variant={matches ? "h6" : "h5"} lineHeight={2}>
        {model.name}
      </Typography>
      <Typography variant={matches ? "caption" : "body"}>
        {model.text}
      </Typography>
    </CardContentWrapper>
  );
}
