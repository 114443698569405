import * as React from "react";
import { useParams } from "react-router-dom";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Box from "@mui/material/Box";
import { useEffect } from "react";
import axios from "axios";
import Map from "../../maps/index";
import EmploymentCard from "../employInfoPages/EmploymentCard";
import CommunityCard from "../commInfoPages/CommmunityCard";
import { Stack } from "@mui/material";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
function createData(categoryName, information) {
  return { categoryName, information };
}

var rows = [];
var data = [];
var nearbyEmployment = [];
var nearbyCommunities = [];
var name = "";
var image = "";
var lat = 0;
var lng = 0;

const valueData = data.map((d) => {
  return d.id.value;
});

let uniqueValueData = [...new Set(valueData)];

const mappedData = uniqueValueData.map((md) => {
  const result = { id: md, subItems: [] };
  data.forEach((d) => {
    if (d.id.value === md) {
      result.subItems.push({ service: d.service });
    }
  });
  return result;
});

const LegalInfo = () => {
  const { id } = useParams();
  const [loaded, setLoaded] = React.useState(false);
  useEffect(() => {
    getInfo();
  }, []);

  const getInfo = async () => {
   
    try {
      nearbyCommunities = [];
      nearbyEmployment = [];
      setLoaded(false);
      const res = await axios.get(
        "https://api.undocumentedtexasimmigrants.me/legal_aid?id=" + id,
      );

      const nearbyRes = await axios.get(
        "https://api.undocumentedtexasimmigrants.me/legal_aid/nearby?id=" + id,
      );

      const nearbyEmploymentReq = nearbyRes.data["nearby_employments"];
      const nearbyCommunitiesReq = nearbyRes.data["nearby_communities"];

      nearbyEmploymentReq.map((entry) => {
        const entryObj = {
          name: entry["name"],
          location: entry["street"],
          website: entry["website"],
          image: entry["photo_link"],
          imageTitle: entry["name"] + " Logo",
          id: entry["id"],
        };
        nearbyEmployment.push(entryObj);
      });

      nearbyCommunitiesReq.map((entry) => {
        const entryObj = {
          communityName: entry["county_name"],
          unauthorizedPop: entry["unauthorized_population"],
          image: entry["photo_link"],
          imageTitle: entry["county_name"],
          id: entry["id"],
        };
        nearbyCommunities.push(entryObj);
      });

      name = res.data["org_name"];

      lat = res.data["latitude"];
      lng = res.data["longitude"];

      image = res.data["photo_link"];

      const arrOfContactInfo = res.data["contact"].split(", ");
      //console.log(arrOfContactInfo);

      var emailAddr = arrOfContactInfo[arrOfContactInfo.length - 1];
      var phoneNum = arrOfContactInfo[0];
      if (arrOfContactInfo.length < 2) {
        if (phoneNum.includes("@")) {
          phoneNum = "Not Available";
        } else {
          emailAddr = "Not Available";
        }
      }

      const arrOfStreetInfo = res.data["location"].split(" ");
      const cityAndState =
        arrOfStreetInfo[arrOfStreetInfo.length - 3] +
        arrOfStreetInfo[arrOfStreetInfo.length - 2];
      //console.log(arrOfStreetInfo);

      rows = [
        createData("Address : ", res.data["location"]),
        createData("City, State : ", cityAndState),
        createData("Phone : ", phoneNum),
        createData("Email : ", emailAddr),
        createData(
          "Website : ",
          <a href={res.data["website"]} target="_blank">
            {res.data["website"]}
          </a>,
        ),
      ];
      setLoaded(true);
    } catch (e) {
      console.log("could not fetch");
    }
  };

  return (
    <>
      <h1 style={{ textAlign: "center" }}>{name}</h1>
      <Box
        component="img"
        sx={{
          height: 300,
          width: 600,
          maxHeight: { xs: 300, md: 250 },
          maxWidth: { xs: 350, md: 2000 },
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
        }}
        alt={name}
        src={image}
      />
      <Stack
        sx={{
          maxWidth: { xs: "90%", md: "70%" },
          paddingTop: "20px",
          margin: "auto",
        }}
      >
        {rows.map((row) => (
          <StyledTableRow key={row.categoryName}>
            <StyledTableCell component="th" scope="row">
              {row.categoryName}
            </StyledTableCell>
            <StyledTableCell align="right">{row.information}</StyledTableCell>
          </StyledTableRow>
        ))}
      </Stack>
      {mappedData.map((item) => {
        <>
          <StyledTableRow>
            <StyledTableCell rowSpan={item.subItems.length + 1}>
              {item.id}
            </StyledTableCell>
          </StyledTableRow>
          {item.subItems.map((detail) => (
            <TableRow>
              <StyledTableCell>{detail.service}</StyledTableCell>
            </TableRow>
          ))}
        </>;
      })}

      {/* Nearby communities and employment centers instances/cards */}
      {/* Map instance */}
      <Stack
        sx={{
          paddingTop: "20px",
          maxWidth: { xs: "90%", md: "70%" },
          margin: "auto",
        }}
      >
        <Map lat={lat} lng={lng} />
      </Stack>
      <Box sx={{ paddingTop: 5 }} />
      <h2
        style={{
          paddingLeft: "20px",
          paddingRight: "20px",
          textAlign: "center",
        }}
      >
        Communities Near This Center
      </h2>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: { xs: "repeat(2, 1fr)", md: "repeat(4, 1fr)" },
          columnGap: "20px",
          paddingLeft: "20px",
          paddingRight: "20px",
        }}
      >
        {nearbyCommunities.map((community) => (
          <CommunityCard community={community} key={community.id}/>
        ))}
      </Box>
      <Box sx={{ paddingTop: 3 }} />
      <h2
        style={{
          paddingLeft: "20px",
          paddingRight: "20px",
          textAlign: "center",
        }}
      >
        Employment Centers Near This Center
      </h2>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: { xs: "repeat(2, 1fr)", md: "repeat(4, 1fr)" },
          columnGap: "20px",
          paddingLeft: "20px",
          paddingRight: "20px",
        }}
      >
        {nearbyEmployment.map((employment) => (
          <EmploymentCard employment={employment} key={employment.id} />
        ))}
      </Box>
    </>
  );
};

export default LegalInfo;
