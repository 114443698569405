import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';
import "./Components.css";

const PieChart = () => {
  const chartRef = useRef(null);

  const fetchData = async (link) => {
    try {
      const response = await fetch(link);
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching data:', error);
      return null;
    }
  };

  const generateChart = async () => {
    const data = await fetchData('https://api.undocumentedtexasimmigrants.me/community');

    if (!data) {
      return;
    }

    for (let i = 0; i < data.length; i++) {
      data[i]['numLegal'] = 0;
    }

    const legal = await fetchData('https://api.undocumentedtexasimmigrants.me/legal_aid');

    for (let i = 0; i < legal.length; i++) {
      let val = legal[i]['community_id'] - 1;
      data[val]['numLegal']++;
    }

    const categories = data.map((item) => item.county_name);
    const numbers = data.map((item) => item.numLegal);

    // Check if there's an existing Chart instance and destroy it
    if (chartRef.current) {
      chartRef.current.destroy();
    }

    const ctx = document.getElementById('myChart').getContext('2d');
    const myChart = new Chart(ctx, {
      type: 'pie',
      data: {
        labels: categories,
        datasets: [
          {
            data: numbers,
            backgroundColor: getColorArray(numbers.length),
          },
        ],
      },
    });

    // Set the chart instance to the ref
    chartRef.current = myChart;
  };

  const getColorArray = (length) => {
    const colors = ['#FF5733', '#33FF57', '#5733FF', '#FF3357', '#FF5733'];
    const colorArray = [];
    for (let i = 0; i < length; i++) {
      colorArray.push(colors[i % colors.length]);
    }
    return colorArray;
  };

  useEffect(() => {
    generateChart();

    // Cleanup function to destroy the chart when the component unmounts
    return () => {
      if (chartRef.current) {
        chartRef.current.destroy();
      }
    };
  }, []); // Run once when the component mounts

  return (
    <div className="chart-container">
    <canvas id="myChart"></canvas>
    </div>
  );
};

export default PieChart;
