import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Button,
  CardActions,
} from "@mui/material";
import Highlighter from "react-highlight-words";
import { Link } from "react-router-dom";

const LegalCard = (props) => {
  const { center } = props;

  const highlightText = (input) => {
    if (props.wordToHighlight != null) {
      return (
        <Highlighter
          highlightClassName="HighlightText"
          searchWords={[props.wordToHighlight]}
          autoEscape={true}
          textToHighlight={input}
        />
      );
    }
    return input;
  };

  return (
    <Box
      sx={{
        justifySelf: "center",
        paddingTop: "20px",
        width: { xs: "40vw", md: "23.5vw" },
        height: "100%",
      }}
    >
      <Card sx={{ maxWidth: 400 }}>
        <Box sx={{ justifyContent: "center" }}>
          <CardMedia
            //height="100"
            //sx={{ height: "100%", width: "100%" }}
            sx={{ height: 140 }}
            //height="auto"
            image={center.photo_link}
            title={center.org_name}
          />
        </Box>
        <CardContent>
          <Typography
            variant="h6"
            color="text.secondary"
            style={{
              textAlign: "center",
              whiteSpace: "nowrap",
              overflow: "hidden",
              fontSize: "100%",
              fontWeight: "bold",
            }}
          >
            {highlightText(center.org_name)}
          </Typography>
          <Typography variant="body1" style={{ textAlign: "center" }}>
            <a href={center.website} target="_blank">
              Website Link
            </a>
          </Typography>
          <Typography variant="body1">Phone Number : {center.phone}</Typography>
          <Typography variant="body1">Email : {center.email}</Typography>
          <Typography variant="body1">Location : {highlightText(center.location)}</Typography>
          <Typography variant="body1">Zip Code : {center.zip}</Typography>
          <Typography
            variant="body1"
            style={{
              display: "-webkit-box",
              WebkitLineClamp: 3,
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
            }}
          >
            Areas of Assistance : {highlightText(center.types_of_assistance)}
          </Typography>
        </CardContent>
        <CardActions>
          <Link to={"/legal-aid/" + center.id}>
            <Button size="small" target="_blank">
              Learn More
            </Button>
          </Link>
        </CardActions>
      </Card>
    </Box>
  );
};

export default LegalCard;
