import React, { useEffect } from "react";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import axios from "axios";
import AreaChart from "./graphs/AreaChart";
import { Box } from "@mui/material";
import "./graphs/Components.css";
import ScatterPlot from "./graphs/ScatterPlot";
import HousingMap from "./graphs/HousingMap";

const DeveloperVisualizations = () => {
  // Your component logic goes here

  return (
    <div>
      <h2 style={{ textAlign: "center" }}>
        High End of Pay vs Low End of Pay for All Employment Opportunities
      </h2>
      <ScatterPlot />
      <hr></hr>
      <AreaChart />
      <hr></hr>
      <h2 style={{ textAlign: "center" }}>
            Housing Locations and Number of Units
          </h2>
          <HousingMap/>
    </div>
  );
};

export default DeveloperVisualizations;
