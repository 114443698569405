import React, { useEffect, useRef, useCallback } from 'react';
import * as d3 from 'd3';
import { legendColor } from 'd3-svg-legend';

const HeatMap = () => {
    const chartRef = useRef(null);

    const fetchData = async (link) => {
        try {
			const response = await fetch(link);
			if (!response.ok) {
				throw new Error(`HTTP error! status: ${response.status}`);
			}
			const data = await response.json();
			return data;
        } catch (error) {
			console.error('Error fetching data:', error);
			return null;
        }
    };

    const generateMap = useCallback(async () => {
        const data = await fetchData('https://api.undocumentedtexasimmigrants.me/community')

		const geoJson = await fetchData('/Texas-Counties-Map.geojson')
        if (!data || !geoJson) {
            return;
        }

        const categories = data.map((item) => item.county_name);
		let modifiedCategories = categories.map(name => name.replace(" County", ""));
        const numbers = data.map((item) => item.unauthorized_population);

		let populationMap = {};
		modifiedCategories.forEach((countyName, index) => {
			populationMap[countyName] = numbers[index];
		});

		const minPopulation = d3.min(numbers)
		const maxPopulation = d3.max(numbers)
		const colorScale = d3.scaleQuantize().domain([minPopulation, maxPopulation]).range(["#deebf7", "#9ecae1", "#6baed6", "#3182bd", "#08519c"]);

		const legend = legendColor().title("Populations").labelFormat(d3.format(".0f")).scale(colorScale);

		const width = 700;
		const height = 600;

		d3.select('svg').remove();
		const svg = d3.select(chartRef.current).append('svg').attr('width', width).attr('height', height);

		const projection = d3.geoMercator().fitSize([width, height], geoJson);
		const pathGenerator = d3.geoPath().projection(projection)

		const tooltip = d3.select('body').append('div').attr('class', 'tooltip').style('position', 'absolute').style('background', 'white').style('border', '1px solid black').style('padding', '5px').style('display', 'none');

		svg.append('g').attr('class', 'legend').attr('transform', 'translate(20,20)').call(legend);
		svg.selectAll('path').data(geoJson.features).enter().append('path').attr('d', pathGenerator).attr('fill', d => {
			const countyName = d.properties.name;
			const population = populationMap[countyName];
			return population ? colorScale(population) : 'gray';
		}).attr('stroke', 'black')
		.on('mouseover', (event, d) => {
			const countyName = d.properties.name;
			const population = populationMap[countyName];
			if (population !== undefined) {
				tooltip.style('display', 'block').html(countyName + '<br>Population: ' + population).style('left', (event.pageX) + 'px').style('top', (event.pageY) + 'px');
			}
		}).on('mouseout', () => {
			tooltip.style('display', 'none');
		});
    }, []);

	useEffect(() => {
		generateMap();
		
		return () => {
			d3.select('svg').remove();
		};
	}, []);

	return React.createElement('div', { className: 'chart-container' }, React.createElement('div', { ref: chartRef }));
};

export default HeatMap;