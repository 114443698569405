import * as React from "react";
import { useEffect } from "react";
import { ThemeProvider } from "@mui/system";
import theme from "../theme";
import Banner from "./SplashComponents/banner";
import { Container } from "@mui/material";
import Splashcards from "./SplashComponents/splashcards";

function Splashpage() {
  useEffect(() => {
    document.title = "Texas Immigrants";
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Container
        maxWidth="xl"
        sx={{
          background: "#fff",
        }}
      >
        <Banner />
        <Splashcards />
      </Container>
    </ThemeProvider>
  );
}

export default Splashpage;
