import { Button } from "@mui/material";
import { CardImage, Splashcard } from "../../../styles/splashcard";
import CardContent from "./CardContent";

export default function SingleCardLarge({ model, matches }) {
  return (
    <>
      <Splashcard>
        <CardImage src={model.image} />
        <CardContent model={model} matches={matches} />
      </Splashcard>
      <Button href={model.explore} variant="contained">
        Explore
      </Button>
    </>
  );
}
