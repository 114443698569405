import axios from "axios";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import SortingAndFiltering from "./SortingAndFiltering";
import EmploymentCard from "./employInfoPages/EmploymentCard";
import CommunityCard from "./commInfoPages/CommmunityCard";
import LegalCard from "./legalInfoPages/LegalCard";

var communities = [];
var legal = [];
var employment = [];
var totalInfo = [];

const SearchPage = () => {
  const { keyword } = useParams();
  const [loaded, setLoaded] = useState(false);
  // const [currentPage, setCurrentPage] = useState(1);
  // const handlePaginationChange = (event, value) => {
  //   setCurrentPage(value);
  //   // console.log(value-1 + " and " + value*8)
  //   // console.log(communityInfo.slice((value -1 ) * 8, (value * 8)+1))
  //   currentPage * 8 >= employmentInfo.length
  //     ? (currentInfo = employmentInfo.slice((value - 1) * 8, value * 8))
  //     : (currentInfo = employmentInfo.slice((value - 1) * 8, value * 8));
  //   //console.log(currentInfo)
  // };
  // const [wordToHighlight, setWordToHighlight] = useState("");

  useEffect(() => {
    getInfo(keyword);
  }, [keyword]);

  const getInfo = async (keyword) => {
    setLoaded(false);
    const res = await axios.get(
      "https://api.undocumentedtexasimmigrants.me/search?id=" + keyword
    );

    console.log(res.data);
    const legalAidInfo = res.data["Legal Aid"];
    legalAidInfo.map((entry) => {
      const locArr = entry["location"].split(" ");
      const zipCode = locArr[locArr.length - 1];
      const arrOfContactInfo = entry["contact"].split(", ");
      var emailAddr = arrOfContactInfo[arrOfContactInfo.length - 1];
      var phoneNum = arrOfContactInfo[0];
      if (arrOfContactInfo.length < 2) {
        if (phoneNum.includes("@")) {
          phoneNum = "Not Available";
        } else {
          emailAddr = "Not Available";
        }
      }
      const entryObj = {
        org_name: entry["org_name"],
        location: entry["location"],
        website: entry["website"],
        learnMore: entry["/legal-aid/Amarillo"],
        zip: zipCode,
        //contact: entry["contact"],
        phone: phoneNum,
        email: emailAddr,
        types_of_assistance: entry["types_of_assistance"],
        photo_link: entry["photo_link"],
        image_name: entry["org_name"] + " logo",
        id: entry["id"],
      };
      legal.push(entryObj);
    });

    const communitiesInfo = res.data["Communities"];
    communitiesInfo.map((entry) => {
      const entryObj = {
        communityName: entry["county_name"],
        unauthorizedPop: entry["unauthorized_population"],
        image: entry["photo_link"],
        imageTitle: entry["county_name"],
        id: entry["id"],
      };
      communities.push(entryObj);
    });

    const employmentInfo = res.data["Employment"];
    employmentInfo.map((entry) => {
      const entryObj = {
        name: entry["name"],
        location: entry["street"],
        website: entry["website"],
        learnMore: "/employment-assistance/AlliedEmployerGroup",
        image: entry["photo_link"],
        imageTitle: entry["name"] + " Logo",
        id: entry["id"],
      };
      employment.push(entryObj);
    });

    setLoaded(true);
  };

  return (
    <>
      <Box>
        {loaded ? (
          <>
            <Typography
              variant="h4"
              sx={{ textAlign: "center", paddingTop: "20px" }}
            >
              Employment Centers:
            </Typography>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: {
                  xs: "repeat(2, 1fr)",
                  md: "repeat(4, 1fr)",
                },
                columnGap: "20px",
                paddingLeft: "20px",
              }}
            >
              {employment.map((employment) => (
                <EmploymentCard
                  employment={employment}
                  wordToHighlight={keyword}
                  key={employment.id+employment.name}
                />
              ))}
            </Box>

            <Typography
              variant="h4"
              sx={{ textAlign: "center", paddingTop: "20px" }}
            >
              Communities:
            </Typography>

            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: {
                  xs: "repeat(2, 1fr)",
                  md: "repeat(4, 1fr)",
                },
                columnGap: "20px",
                paddingLeft: "20px",

              }}
            >
              {communities.map((community) => (
                <CommunityCard
                  community={community}
                  wordToHighlight={keyword}
                  key={community.id + community.communityName}
                />
              ))}
            </Box>
            <Typography
              variant="h4"
              sx={{ textAlign: "center", paddingTop: "20px" }}
            >
              Legal Centers:
            </Typography>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: {
                  xs: "repeat(2, 1fr)",
                  md: "repeat(4, 1fr)",
                },
                columnGap: "20px",
                paddingLeft: "20px",

              }}
            >
              {legal.map((legal) => (
                <LegalCard
                  center={legal}
                  wordToHighlight={keyword}
                  key={legal.id+legal.org_name}
                />
              ))}
            </Box>
            {/* <Stack alignItems="center" sx={{ paddingTop: "20px" }}>
            <Pagination
              size="large"
              count={Math.ceil(employmentInfo.length / 8)}
              page={currentPage}
              onChange={handlePaginationChange}
            />
          </Stack>
          <Typography variant="h6" sx={{ padding: "5px" }}>
            Instances: {employmentInfo.length}
          </Typography> */}
          </>
        ) : (
          <Typography variant="h3">Loading... </Typography>
        )}
      </Box>
    </>
  );
};

export default SearchPage;
