import { useTheme } from "@mui/material/styles";
import { Container, Grid, useMediaQuery } from "@mui/material";
import SingleCard from "./SingleCard";

import communitiesimage from "../../../assets/splashpageImages/communities.jpeg";
import legalaidimage from "../../../assets/splashpageImages/legalaid.jpeg";
import employmentimage from "../../../assets/splashpageImages/employment.jpeg";
import SingleCardLarge from "./SingleCardLarge";

const models = [
  {
    id: 1,
    name: "Communities",
    text: "Find Communities",
    explore: "/communities",
    image: communitiesimage,
    imagename: "Communities Model Image",
  },
  {
    id: 2,
    name: "Legal Aid",
    text: "Get Legal Assistance",
    explore: "/legal-aid",
    image: legalaidimage,
    imagename: "Legal Aid Model Image",
  },
  {
    id: 3,
    name: "Employment",
    text: "Explore Employment Opportunities",
    explore: "/employment-assistance",
    image: employmentimage,
    imagename: "Employment Model Image",
  },
];

export default function Splashcards() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  const cards = models.map((model) => (
    <Grid
      item
      key={model.id}
      xs={2}
      sm={4}
      display="flex"
      flexDirection={"column"}
      alignItems="center"
    >
      {matches ? (
        <SingleCard model={model} matches={matches} />
      ) : (
        <SingleCardLarge model={model} matches={matches} />
      )}
    </Grid>
  ));

  return (
    <Container>
      <Grid
        container
        spacing={{ xs: 2, md: 3 }}
        justifyContent="center"
        sx={{ margin: `20px 4px 10px 4px` }}
        columns={{ xs: 2, sm: 8, md: 12 }}
      >
        {cards}
      </Grid>
    </Container>
  );
}
