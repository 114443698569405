import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useState, useEffect } from "react";
import axios from "axios";
import CommunityCard from "./commInfoPages/CommmunityCard";
import { Button, Pagination } from "@mui/material";
import { Stack } from "@mui/material";
import SortingAndFiltering from "./SortingAndFiltering";

var communityInfo = [
  {
    communityName: "Bexar County, TX",
    unauthorizedPop: "80,000",
    learnMore: "/communities/Bexar",
    image:
      "https://upload.wikimedia.org/wikipedia/commons/4/4e/Bexar_County_Courthouse_2015.jpg",
    imageTitle: "bexar county",
    communityId: "1",
  },
  {
    communityName: "Cameron County, TX",
    unauthorizedPop: "36,000",
    learnMore: "/communities/Cameron",
    image:
      "https://www.cameroncountytx.gov/wp-content/uploads/revslider/business-05-1/DancyFrontViewx1.jpg",
    imageTitle: "cameron county",
    communityId: "1",
  },
  {
    communityName: "Collin County, TX",
    unauthorizedPop: "48,000",
    learnMore: "/communities/Collin",
    image:
      "https://communityimpact.com/uploads/wpengine/uploads/2019/07/Collin-County_by-LJ.jpeg",
    imageTitle: "collin county",
    communityId: "1",
  },
];

var sortAttributes = ["County Name", "Unauthorized Population","Countries Of Birth Numbers", "< 5 Years Of US Residence Population","<16 Years Of Age Population"];

var currentInfo = [];

const Communities = (props) => {
  const [loaded, setLoaded] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  var [sortType, setSortType] = React.useState("ascending");
  var [currentAttribute, setCurrentAttribute] = React.useState("");
  var[resetPressed, setResetPressed] = React.useState(false);
  var [result, setResult] = React.useState("");
  var [keyword, setKeyword] = React.useState("");

  const handlePaginationChange = (event, value) => {
    setCurrentPage(value);
    // console.log(value-1 + " and " + value*8)
    // console.log(communityInfo.slice((value -1 ) * 8, (value * 8)+1))
    currentPage * 8 >= communityInfo.length
      ? (currentInfo = communityInfo.slice((value - 1) * 8, value * 8))
      : (currentInfo = communityInfo.slice((value - 1) * 8, value * 8 + 1));
    //console.log(currentInfo)
  };

  //https://api.undocumentedtexasimmigrants.me/community?sort=county_name&order=asc
  
  useEffect(() => {
    findCommunities(
      setLoaded,
      sortType,
      currentAttribute,
      resetPressed, 
      result
      
    );
  }, [sortType, currentAttribute, resetPressed, result]);

  const findCommunities = async (setLoaded, sortType, currentAttribute, resetPressed, result) => {
    try {
      setLoaded(false);
      var url = "https://api.undocumentedtexasimmigrants.me/community?";
  
      if (resetPressed) {
        console.log("reset pressed2")
        setResetPressed(false);
        setCurrentAttribute("");
        setSortType("ascending");
        setKeyword("");
        return url 
      }
  
      if(currentAttribute != ""){
        if(currentAttribute == "County Name") {
          url+= "sort=county_name";
        } else if(currentAttribute == "Unauthorized Population"){
          url += "sort=unauthorized_population";
        } else if (currentAttribute == "Countries of Birth Numbers"){
          url += "sort=countries_of_birth_numbers";
        } else if (currentAttribute == "< 5 Years Of US Residence Population"){
          url += "sort=years_of_us_residence_numbers";
        } else if (currentAttribute == "<16 Years Of Age Population"){
          url += "sort=age_numbers";
        }
        console.log("sort changed, url is now:" + url);
      } else {
        //default sort 
        url += "sort=county_name"
        console.log("default sort, url is now:" + url);
      }
  
      if(sortType != ""){
        if (sortType == "ascending") {
          url += "&order=asc";
        } else {
          url += "&order=desc";
        }
        console.log("order changed, url is now:" + url);
      } else {
        //default order 
        url += "&order=asc" 
        console.log("default order, url is now:" + url);
      }
      console.log("URL IS:" + url);

      if (result != ""){
        console.log("generating URL, result is: "+result);
        url = "https://api.undocumentedtexasimmigrants.me/search/community?id=" + result;
        setKeyword(result);
        setResult("");
      }
  
      
      const res = await axios.get(url);
      communityInfo = [];
      // console.log(res.data)
      res.data.map((entry) => {
        const entryObj = {
          communityName: entry["county_name"],
          unauthorizedPop: entry["unauthorized_population"],
          image: entry["photo_link"],
          imageTitle: entry["county_name"],
          id: entry["id"],
        };
        communityInfo.push(entryObj);
      });
      //console.log(communityInfo);
      currentInfo = communityInfo.slice(0, 8);
      setLoaded(true);
    } catch (e) {
      console.log("Could not fetch: " + e);
    }
  };


  return (
    <>
      {loaded ? (
        <>
          <SortingAndFiltering 
            model="/communities/"
            sortAttributes={sortAttributes} 
            sortType={sortType} 
            setSortType={setSortType}
            currentAttribute={currentAttribute}
            setCurrentAttribute={setCurrentAttribute}
            setResult={setResult}/> 


          <Box textAlign='center'>
            <Button variant="contained" sx={{ marginLeft: "10px", marginTop: "12px"}}
            onClick={() => {
              setResetPressed(true);
              console.log("reset pressed");
            }}
            >
              Reset
            </Button>
          </Box>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: {
                xs: "repeat(2, 1fr)",
                md: "repeat(4, 1fr)",
              },
              columnGap: "20px",
            }}
          >
            {currentInfo.map((community) => (
              <CommunityCard community={community} key={community.id} wordToHighlight={keyword}/>
            ))}
          </Box>
          <Stack alignItems="center" sx={{ paddingTop: "20px" }}>
            <Pagination
              size="large"
              count={Math.ceil(communityInfo.length / 8)}
              page={currentPage}
              onChange={handlePaginationChange}
            />
          </Stack>
          <Typography variant="h6" sx={{ padding: "5px" }}>
            Instances: {communityInfo.length}
          </Typography>
        </>
      ) : (
        <Typography variant="h3">Loading... </Typography>
      )}
    </>
  );
};

export default Communities;
