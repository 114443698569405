import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";

import { Link } from "react-router-dom";

import { useNavigate } from "react-router-dom";

const pages = {
  "Communities": "/communities",
  "Legal Assistance": "/legal-aid",
  "Employment Centers": "/employment-assistance",
  "About": "/about",
  "Our Visualizations": "/visualizations",
  "Developers Visualizations": "/developers-visualizations",
};

function Navbar() {
  const navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const [searchValue, setSearchValue] = React.useState("");
  const handleTabClick = (path) => {
    setAnchorElNav(null);
    navigate(path);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      // Navigate to the search page when Enter key is pressed
      if (searchValue) {
        window.location.href = `/search/${searchValue}`;
      } else {
        // Handle empty search input, e.g., display a message
        console.log("Search input is empty");
      }
    }
  };

  return (
    <AppBar position="static" sx={{ marginLeft: 0 }}>
      <Box
        sx={{
          paddingLeft: "10px",
          paddingRight: "10px",
          justifyContent: "space-between",
        }}
      >
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            component="a"
            href="/"
            sx={{
              display: { xs: "none", md: "flex" },
              // overflowWrap: { xs: "nowrap", md: "wrap" },
              fontWeight: 500,
              color: "inherit",
              textDecoration: "none",
              textAlign: "center",
            }}
          >
            Undocumented Texas Immigrants
          </Typography>
          {/* Dropdown on small screen */}
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={() => handleTabClick()}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {Object.keys(pages).map((key) => (
                <MenuItem key={key} onClick={() => handleTabClick(pages[key])}  >
                  <Typography textAlign="center">{key}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          {/* Regular Tab Bar */}
          <Typography
            variant="h6"
            component="a"
            href="/"
            sx={{
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontWeight: 500,
              color: "inherit",
              textDecoration: "none",
              textAlign: "center",
            }}
          >
            Undocumented Texas Immigrants
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {Object.keys(pages).map((key) => {
              return (
                <Button
                  key={key}
                  onClick={() => handleTabClick(pages[key])}
                  sx={{  color: "white", display: "block" }}
                  style={{ textOverflow:'ellipsis', overflow: 'hidden'}}
                >
                  {key}
                </Button>
              );
            })}
          </Box>

          <Box sx={{ padding: "20px" }}>
            <TextField
              id="outlined-basic"
              label="Search"
              variant="filled"
              sx={{ backgroundColor: "white",  }}
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              onKeyDown={handleKeyPress}
            />
          </Box>
          <Link to={"/search/" + searchValue}>
            <SearchIcon />
          </Link>
        </Toolbar>
      </Box>
    </AppBar>
  );
}
export default Navbar;
