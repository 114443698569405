import React, {useState} from "react";
import {FaSearch} from "react-icons/fa"
import "./SearchBar.css"

export const SearchBar = ({setResult}) => {
    const [input, setInput] = useState("");

    // const handleSearch = (value) => {
    //     // setInput(value)
    //     // console.log("setInput in handleSearch, input is: "+input);
    //     setResult(input);
    //     // console.log("set result in handleSearch, result is: "+ result);
    // }

    const handleKeyPress = (e) => {
        if (e.key === "Enter") {
          // Navigate to the search page when Enter key is pressed
          if (input) {
            setResult(input);
          } else {
            // Handle empty search input, e.g., display a message
            console.log("Search input is empty");
          }
        }
      };

    return(
        <div className="input-wrapper">
            <FaSearch id="search-icon" />
            <input 
                placeholder="Type to search..." 
                value={input} 
                onChange={(e) => setInput(e.target.value)}
                onKeyDown={handleKeyPress}
            />
        </div>
    );
};
