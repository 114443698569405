import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import { MarkerF } from "@react-google-maps/api";

export default function Map({ lat, lng }) {
  // const { isLoaded } = useLoadScript({
  //     googleMapsApiKey: "AIzaSyATON66DaxgIejFbAxWs47qqDiClgX_lA4",
  // });

  // if(!isLoaded) return <div>Loading...</div>
  const { isLoaded } = useJsApiLoader({
    // googleMapsApiKey: process.env.REACT_GOOGLE_MAPS_API,
    googleMapsApiKey: "AIzaSyAcIC_VdySRt3DL1xzVlGhvMKzrNR02lak",
  });

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  return (
    <GoogleMap
      zoom={15}
      center={{ lat: lat, lng: lng }}
      mapContainerClassName="map-container"
      mapContainerStyle={{
        height: "400px",
        border: "2px solid transparent",
        margin: "auto",
      }}
    >
      <MarkerF
        position={{ lat: lat, lng: lng }}
        icon={{
          url: "https://maps.google.com/mapfiles/ms/icons/red-dot.png",
          scaledSize: new window.google.maps.Size(40, 40),
        }}
      />
    </GoogleMap>
  );
}
