import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import axios from "axios";
import { useState, useEffect } from "react";
import LegalCard from "./legalInfoPages/LegalCard";
import { Pagination, Stack } from "@mui/material";
import SortingAndFiltering from "./SortingAndFiltering";
import FilterDropdown from "./FilterDropdown";
import Button from "@mui/material/Button";


//import Grid from '@mui/material/Unstable_Grid2';

import advocatelogo from "../assets/legalcards/advocates.webp";
import africanlawlogo from "../assets/legalcards/africanlawlogo.png";
import amarillologo from "../assets/legalcards/amarillologo.webp";

var legalAidInfo = [
  {
    name: "Advocates for Immigrant Survivors",
    location: "Houston, TX",
    website: "https://advocatesforsurvivors.org/",
    learnMore: "/legal-aid/Advocates",
    zip: "77008",
    phone: "(832) 644-5524",
    assistance: "8",
    image: advocatelogo,
    imagename: "Advocates for Immigrant Survivors logo",
    id: 1,
  },
  {
    name: "African Law Center",
    location: "Houston, TX",
    website: "http://www.africanlawcenter.org",
    learnMore: "/legal-aid/AfricanLaw",
    zip: "77036",
    phone: "(281) 624-6421",
    assistance: "16",
    image: africanlawlogo,
    imagename: "African Law Center logo",
    id: 1,
  },
  {
    name: "Amarillo Immigrant Resource Center",
    location: "Amarillo, TX ",
    website: "https://amahope.org/",
    learnMore: "/legal-aid/Amarillo",
    zip: "79107",
    phone: "(806) 335-5734",
    assistance: "12",
    image: amarillologo,
    imagename: "Amarillo Immigrant Resource Center logo",
    id: 1,
  },
];

var sortAttributes = ["org_name", "zipcode", "location"];
var currentInfo = [];

const LegalAid = () => {
  const [loaded, setLoaded] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  var [sortType, setSortType] = React.useState("ascending");
  var [currentAttribute, setCurrentAttribute] = React.useState("");
  var [filterType, setFilterType] = React.useState("");
  var [targetValue, setTargetValue] = React.useState("");
  var [resetPressed, setResetPressed] = React.useState(false);
  var [result, setResult] = React.useState("");
  var [keyword, setKeyword] = React.useState("");


  const handlePaginationChange = (event, value) => {
    setCurrentPage(value);
    // console.log(value-1 + " and " + value*8)
    // console.log(communityInfo.slice((value -1 ) * 8, (value * 8)+1))
    currentPage * 8 >= legalAidInfo.length
      ? (currentInfo = legalAidInfo.slice((value - 1) * 8, value * 8))
      : (currentInfo = legalAidInfo.slice((value - 1) * 8, value * 8));
    //console.log(currentInfo)
  };
  useEffect(() => {
    findAllLegalAidCenters(
      setLoaded,
      sortType,
      currentAttribute,
      filterType,
      targetValue, 
      resetPressed,
      result 
    );
  }, [sortType, currentAttribute, filterType, targetValue, resetPressed, result]);

  const generateURL = (sortType, currentAttribute, filterType, targetValue, resetPressed, result) => {
    var url = "https://api.undocumentedtexasimmigrants.me/legal_aid"; 

    if (resetPressed) {
      console.log("reset pressed2")
      setResetPressed(false);
      setCurrentAttribute("");
      setSortType("ascending");
      setKeyword("");
      return url 
    }
    if (result != ""){
      console.log("generating URL, result is: "+result);
      url = "https://api.undocumentedtexasimmigrants.me/search/legalaid?id=" + result;
      setKeyword(result);
      setResult("");
      return url;
    }

    //set the sortType
    if(sortType != ""){
      if(sortType == "ascending"){
        sortType = "asc"
      } else if(sortType == "descending"){
        sortType = "desc"
      }
      url = url + "?sort=" + currentAttribute + "&order=" + sortType;
    } 

    if(sortType == ""){
      url = url + "?sort=" + currentAttribute + "&order=asc";
    }

    if(filterType != ""){
      url = "https://api.undocumentedtexasimmigrants.me/legal_aid"; 
      if(filterType == "filterByZipcode"){
        filterType = "zipcode";
        setCurrentAttribute("");
      } else if(filterType == "filterByPhone"){
        filterType = "phone";
        setCurrentAttribute("");
      } else if(filterType == "filterByWebsite"){
        filterType = "website";
        setCurrentAttribute("");
        url = url + "?filter=" + filterType; 
        setFilterType("");
        setTargetValue ("");
        return url
      }

      url = url + "?filter=" + filterType + "&value=" + targetValue;
      setFilterType("");
      setTargetValue("");
    }

    
    return url 
  }

  const findAllLegalAidCenters = async (setLoaded, sortType, currentAttribute, filterType, targetValue, resetPressed, result) => {
    try {
      setLoaded(false);
      var url = generateURL(sortType, currentAttribute, filterType, targetValue, resetPressed, result);
      const res = await axios.get(url);
      legalAidInfo = [];
      res.data.map((entry) => {
        const locArr = entry["location"].split(" ");
        const zipCode = locArr[locArr.length - 1];
        const arrOfContactInfo = entry["contact"].split(", ");
        var emailAddr = arrOfContactInfo[arrOfContactInfo.length - 1];
        var phoneNum = arrOfContactInfo[0];
        if (arrOfContactInfo.length < 2) {
          if (phoneNum.includes("@")) {
            phoneNum = "Not Available";
          } else {
            emailAddr = "Not Available";
          }
        }
        const entryObj = {
          org_name: entry["org_name"],
          location: entry["location"],
          website: entry["website"],
          learnMore: entry["/legal-aid/Amarillo"],
          zip: zipCode,
          //contact: entry["contact"],
          phone: phoneNum,
          email: emailAddr,
          types_of_assistance: entry["types_of_assistance"],
          photo_link: entry["photo_link"],
          org_name: entry["org_name"] + " logo",
          id: entry["id"],
        };
        legalAidInfo.push(entryObj);
      });
      currentInfo = legalAidInfo.slice(0, 8);
      setLoaded(true);
    } catch (e) {
      console.log("Could not fetch: " + e);
    }
  };



  var zipOptions = ['75247', '75710', '76115', '76701', '77007', '77008', '77036', '77072', '77074', '77703', '78040', '78207', '78408', '78501', '78550', '78702', '78704', '78752', '78754', '79013', '79107', '79401' ];

  var phoneOptions = ['210', '214', '254', '281', '346', '361', '409', '713', '806', '817', '830', '832', '903', '915', '972'];

  return (
    <>
      {loaded ? (
        <>
          <SortingAndFiltering 
            model="/legal-aid/"
            sortAttributes={sortAttributes} 
            sortType={sortType} 
            setSortType={setSortType}
            currentAttribute={currentAttribute}
            setCurrentAttribute={setCurrentAttribute} 
            setResult={setResult}/>

          <Box textAlign = 'center'>
            <FilterDropdown
              name="Zipcode"
              options={zipOptions}
              model="/legal-aid/"
              filterType="filterByZipcode"
              setFilterType={setFilterType}
              targetValue={targetValue}
              setTargetValue={setTargetValue}
            />

            <FilterDropdown
              name="Area Code"
              options={phoneOptions}
              model="/legal-aid/"
              filterType="filterByPhone"
              setFilterType={setFilterType}
              targetValue={targetValue}
              setTargetValue={setTargetValue}
            />

            <FilterDropdown
              name = "Website"
              options = {["Has Website Available"]}
              model="/legal-aid/"
              filterType="filterByWebsite"
              setFilterType={setFilterType}
              targetValue={targetValue}
              setTargetValue={setTargetValue}
            />
          </Box>

          <Box textAlign='center'>
            <Button variant="contained" sx={{ marginLeft: "10px" }}
            onClick={() => {
              setResetPressed(true);
              console.log("reset pressed");
            }}
            >
              Reset
            </Button>
          </Box>

          <Box>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: {
                  xs: "repeat(2, 1fr)",
                  md: "repeat(4, 1fr)",
                },
              }}
            >
              {currentInfo.map((center) => (
                <LegalCard center={center} key={center.id} wordToHighlight={keyword}/>
              ))}
            </Box>
          </Box>
          <Stack alignItems="center" sx={{ paddingTop: "20px" }}>
            <Pagination
              size="large"
              count={Math.ceil(legalAidInfo.length / 8)}
              page={currentPage}
              onChange={handlePaginationChange}
            />
          </Stack>
          <Typography variant="h6" sx={{ padding: "5px" }}>
            Instances: {legalAidInfo.length}
          </Typography>
        </>
      ) : (
        <Typography variant="h3">Loading...</Typography>
      )}
    </>
  );
};

export default LegalAid;
