import React from 'react';
import "./graphs/Components.css";
import PieChart from './graphs/PieChart';
import HeatMap from './graphs/HeatMap';
import BarChart from './graphs/BarChart';

const OurVisualizations = () => {

  return (
    <div>
      <h2 style={{ textAlign: 'center' }}># Legal Aid Centers Per County</h2>
      <PieChart />
      <hr></hr>
      <h2 style={{ textAlign: 'center'}}># Employment Centers Per County</h2>
      <BarChart />
      <hr></hr>
      <h2 style={{ textAlign: 'center' }}># Unauthorized Population Heat Map</h2>
      <HeatMap />
    </div>
  );
};

export default OurVisualizations;
