import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { useState, useEffect } from "react";
import Link from "@mui/material/Link";
import abhinav from "../assets/abhinav.png";
import prerna from "../assets/prerna.png";
import anthony from "../assets/anthony.png";
import ben from "../assets/ben.png";
import jennie from "../assets/jennie.jpg";

import { CardMedia } from "@mui/material";

const teamInfo = [
  {
    name: "Abhinav Varre",
    gitlabName: "AbhinavVarre",
    altGitlabName: "Abhinav Varre",
    gitlabID: "@varre.abhinav",
    image: abhinav,
    role: "Front-end",
    bio: "I'm a Junior, and I like playing piano, guitar, and woodworking in my freetime.",
    commits: 0,
    issues: 0,
    unitTests: 10,
  },
  {
    name: "Anthony Nguyen",
    gitlabName: "Anthony Tai Nguyen",
    altGitlabName: "Anthony Tai Nguyen",
    gitlabID: "@tai2025",
    image: anthony,
    role: "Back-end",
    bio: " I'm a Junior. I love playing tennis at the IM fields. I've had work experience with API development, but I want to dabble in other spaces.",
    commits: 0,
    issues: 0,
    unitTests: 24,
  },
  {
    name: "Ben Wasden",
    gitlabName: "bwasden2003",
    altGitlabName: "Ben Wasden",
    gitlabID: "@bwasden2003",
    image: ben,
    role: "Back-end",
    bio: "I'm a Junior whose very interested in backend development. Outside of CS I like to play golf and rock climb.",
    commits: 0,
    issues: 0,
    unitTests: 13,
  },
  {
    name: "Jennie Yuan",
    gitlabName: "Jennie Yuan",
    altGitlabName: "yyuanjing",
    gitlabID: "@jennieyuan",
    image: jennie,
    role: "Front-end/Deployment",
    bio: "I'm a Senior, and I like painting and my cats.",
    commits: 0,
    issues: 0,
    unitTests: 11,
  },
  {
    name: "Prerna P Singh",
    gitlabName: "Prerna Singh",
    altGitlabName: "Prerna P Singh",
    gitlabID: "@singhprernap",
    image: prerna,
    role: "Front-end/Back-end",
    bio: "I'm a Junior, and I like playing basketball, going to the movies, and travelling.",
    commits: 0,
    issues: 0,
    unitTests: 10,
  },
];

const toolInfo = [
  {
    name: "React",
    image: "https://cdn.worldvectorlogo.com/logos/react-1.svg",
    description: "A JavaScript framework for front-end development",
    link: "https://reactjs.org/",
  },
  {
    name: "Postman",
    image: "https://cdn.worldvectorlogo.com/logos/postman.svg",
    description: "A tool for designing, testing, and documenting our APIs",
    link: "https://www.postman.com/",
  },
  {
    name: "GitLab",
    image: "https://cdn.worldvectorlogo.com/logos/gitlab.svg",
    description: "Git repository and CI/CD platform",
    link: "https://about.gitlab.com/",
  },
  {
    name: "MaterialUI",
    image: "https://cdn.worldvectorlogo.com/logos/material-ui-1.svg",
    description: "A library for pre - built React components",
    link: "https://react-bootstrap.github.io/",
  },
  {
    name: "NameCheap",
    image: "https://cdn.worldvectorlogo.com/logos/namecheap.svg",
    description: "A service to obtain domain names",
    link: "https://www.namecheap.com/",
  },
  {
    name: "Flask",
    image: "https://cdn.worldvectorlogo.com/logos/flask.svg",
    description: "An API framework for building APIs in Python",
    link: "https://flask.palletsprojects.com/en/2.2.x/",
  },
  {
    name: "PostgreSQL",
    image: "https://cdn.worldvectorlogo.com/logos/postgresql.svg",
    description: " A Database Management Server",
    link: "https://www.mysql.com/",
  },
  {
    name: "AWS Amplify",
    image: "https://miro.medium.com/v2/resize:fit:1200/0*y-ZimaRh8fnftZVZ",
    description: "A AWS service used to host the Web application",
    link: "https://aws.amazon.com/amplify/",
  },
];

const About = () => {
  const [loadedCommits, setLoadedC] = useState(false);
  const [loadedIssues, setLoadedI] = useState(false);
  const [teamList, setTeamList] = useState([]);
  const [totalCommits, setTotalCommits] = useState(0);
  const [totalIssues, setTotalIssues] = useState(0);

  async function getCommitInfo() {
    fetch(`https://gitlab.com/api/v4/projects/50543108/repository/contributors`)
      .then((response) => response.json())
      .then((data) => {
        teamInfo.forEach((member) => {
          member.commits = 0;
        });
        setTotalCommits(0);
        var commits = 0;
        data.forEach((item) => {
          teamInfo.forEach((member) => {
            if (
              member.gitlabName === item.name ||
              member.name === item.name ||
              member.altGitlabName === item.name
            ) {
              // console.log(member.name);
              // console.log(item.commits);
              member.commits += item.commits;
              commits += item.commits;
            }
          });
        });
        setTotalCommits(commits);
        setTeamList(teamInfo);
        setLoadedC(true);
      });
  }

  async function getIssuesInfo() {
    fetch(`https://gitlab.com/api/v4/projects/50543108/issues?per_page=100`)
      .then((response) => response.json())
      .then((data) => {
        teamInfo.forEach((member) => {
          member.issues = 0;
        });
        setTotalIssues(0);
        var issues = 0;
        data.forEach((item) => {
          let found = false;
          //console.log(item)
          teamInfo.forEach((member) => {
            if (item.assignee != null) {
              if (
                member.gitlabName === item.assignee.name ||
                member.altGitlabName === item.assignee.name
              ) {
                member.issues += 1;
                issues += 1;
                found = true;
              }
            }
          });
          if (!found) {
            teamInfo.forEach((member) => {
              if (item.closed_by != null) {
                if (member.gitlabName === item.closed_by.name) {
                  member.issues += 1;
                  issues += 1;
                }
              }
            });
          }
        });
        setTotalIssues(issues);
        setTeamList(teamInfo);
        setLoadedI(true);
      });
  }

  function getTotalTest() {
    var result = 0;
    teamInfo.forEach((member) => {
      result += member.unitTests;
    });
    return result;
  }

  useEffect(() => {
    getIssuesInfo();
    getCommitInfo();
  }, []);

  return (
    <>
      <Box sx={{}} data-testid="about-cards">
        <Typography variant="h3" padding={"20px"} textAlign={"center"}>
          Meet our Team!
        </Typography>
        <Typography variant="h5" padding={"20px"}>
          Our project aims at providing assistance to the undocumented
          immigrants near the Texas border. We aim to present information about
          where these immigrant communities are located, employment assistance
          and skill development for immigrants, and crucial legal resources as
          well.
        </Typography>
        <Box padding={"20px"}>
          <b>
            {" "}
            Explanation of the interesting result of integrating disparate data:{" "}
          </b>{" "}
          The Integration between the various Communities, Employment Aid
          Resources and Legal Aid Resources allows for an integrated information
          application where those that need to can find all the information they
          need in one place. One interesting finding from combining different
          datasets is that when data points are close to each other in location,
          they tend to have similar links. This means that nearby data points
          often share common connections, making the integrated platform more
          connected in those areas.
        </Box>
        <Typography variant="h7" padding={"20px"}></Typography>
        <br></br>
        <Box textAlign={"center"}>
          <Link href="https://documenter.getpostman.com/view/30034938/2s9YJZ3jae">
            API Docs
          </Link>
          <br></br>
          <Link href="https://gitlab.com/varre.abhinav/undocumented-texas-immigrants">
            Our Repo
          </Link>
        </Box>
      </Box>
      {loadedCommits && loadedIssues ? (
        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexWrap: {
                xs: "wrap",
                md: "nowrap",
              },
            }}
          >
            {teamList.map((member) => (
              <Box key={member.name}>
                <Box sx={{ justifySelf: "center" }}>
                  <Card
                    sx={{
                      maxWidth: { xs: "165px", md: "250px" },
                      padding: "10px",
                    }}
                  >
                    <Box sx={{}}>
                      <Box sx={{ justifyContent: "center" }}>
                        {/* <img sx={{objectFit: "contain"}} src={member.image} alt="profile pic"></img> */}
                        <CardMedia
                          component="img"
                          height="300"
                          image={member.image}
                          alt="Profile pic"
                        />
                      </Box>
                      <CardContent sx={{ textAlign: "center" }}>
                        <Typography variant="h6" color="text.secondary">
                          {member.name}
                        </Typography>
                        <Typography variant="h6" color="text.secondary">
                          Role: {member.role}
                        </Typography>
                        <Typography variant="body1">{member.bio}</Typography>
                        <br></br>
                        <Typography sx={{ mb: 1.5 }} color="text.secondary">
                          # of commits: {member.commits}
                        </Typography>
                        <Typography sx={{ mb: 1.5 }} color="text.secondary">
                          # of issues: {member.issues}
                        </Typography>
                        <Typography sx={{ mb: 1.5 }} color="text.secondary">
                          # of unit tests: {member.unitTests}
                        </Typography>
                      </CardContent>
                    </Box>
                  </Card>
                </Box>
              </Box>
            ))}
          </Box>
          <Box>
            <br></br>
            <br></br>
            <br></br>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-around",
                flexWrap: "wrap",
                paddingBottom: "100px",
              }}
            >
              <Typography variant="h4" align="center">
                Total Commits: {totalCommits}
              </Typography>
              <Typography variant="h4" align="center">
                Total Issues: {totalIssues}
              </Typography>
              <Typography variant="h4" align="center">
                Total Tests: {getTotalTest()}
              </Typography>
            </Box>
            <Typography
              variant="h3"
              sx={{ textAlign: "center", padding: "10px" }}
            >
              Our Toolchain:
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-around",
              }}
            >
              {toolInfo.map((tool) => {
                return (
                  <Box key={tool.name}>
                    <Box sx={{ padding: "10px" }}>
                      <Card
                        sx={{
                          width: { xs: "145px", md: "300px" },
                          padding: "10px",
                        }}
                      >
                        <CardMedia
                          component="img"
                          image={tool.image}
                          height="200"
                          sx={{ objectFit: "contain" }}
                        />
                        <CardContent>
                          <Typography variant="h6" color="text.secondary">
                            {tool.name}
                          </Typography>
                          <Typography sx={{ mb: 1.5 }} color="text.secondary">
                            {tool.description}
                          </Typography>
                        </CardContent>
                      </Card>
                    </Box>
                  </Box>
                );
              })}
            </Box>
          </Box>
        </Box>
      ) : (
        <h1>Loading...</h1>
      )}
    </>
  );
};

export default About;

//React, AWS Amplify, AWS Route53, Postman, Flask, Namecheap, MaterialUI,
