import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./Navbar";

//Router imports
import Splashpage from "./components/Splashpage";
import LegalAid from "./components/LegalAid";
import LegalInfo from "./components/legalInfoPages/LegalInfo";
import Employment from "./components/Employment";
import EmploymentInfo from "./components/employInfoPages/EmploymentInfo";
import Communities from "./components/Communities";
import CommunityInfo from "./components/commInfoPages/CommmunityInfo";
import About from "./components/About";
import SearchPage from "./components/SearchPage";
import OurVisualizations from "./components/OurVisualizations";
import DeveloperVisualizations from "./components/DeveloperVisualizations";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { Routes, Route } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
      <Routes>
        <Route exact path="/" element={<Splashpage />} />
        <Route exact path="/communities/:id" element={<CommunityInfo />} />
        <Route
          exact
          path="/communities/:sort?/:order?"
          element={<Communities />}
        />
        <Route exact path="/legal-aid/:id" element={<LegalInfo />} />
        <Route exact path="/legal-aid/:sort?/:order?" element={<LegalAid />} />
        <Route
          exact
          path="/employment-assistance/:id"
          element={<EmploymentInfo />}
        />
        <Route
          exact
          path="/employment-assistance/:sort?/:order?"
          element={<Employment />}
        />
        <Route exact path="/search/:keyword" element={<SearchPage />} />
        <Route exact path="/about" element={<About />} />
        <Route exact path="/visualizations" element={<OurVisualizations />} />
        <Route
          exact
          path="/developers-visualizations"
          element={<DeveloperVisualizations />}
        />
        <Route
          path="*"
          element={<h1>404: You're looking for a page that doesn't exist</h1>}
        />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
