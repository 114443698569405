import * as React from "react";
import { useParams } from "react-router-dom";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import axios from "axios";
import { useEffect } from "react";
import Map from "../../maps/index";
import CommunityCard from "../commInfoPages/CommmunityCard";
import LegalCard from "../legalInfoPages/LegalCard";
import { Stack } from "@mui/material";

function createData(categoryName, information) {
  return { categoryName, information };
}

var rows = [];
var nearbyLegal = [];
var nearbyCommunities = [];
var name = "";
var image = "";
var lat = 0;
var lng = 0;

const EmploymentInfo = () => {
  const { id } = useParams();
  const [loaded, setLoaded] = React.useState(false);

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  useEffect(() => {
    getInfo();
  }, []);

  const getInfo = async () => {
    try {
      nearbyLegal = [];
      nearbyCommunities = [];
      setLoaded(false);
      const res = await axios.get(
        "https://api.undocumentedtexasimmigrants.me/employment?id=" + id,
      );

      const nearbyRes = await axios.get(
        "https://api.undocumentedtexasimmigrants.me/employment/nearby?id=" + id,
      );

      const nearbyLegalReq = nearbyRes.data["nearby_legalAids"];
      const nearbyCommunitiesReq = nearbyRes.data["nearby_communities"];

      nearbyLegalReq.map((entry) => {
        const locArr = entry["location"].split(" ");
        const zipCode = locArr[locArr.length - 1];
        const entryObj = {
          name: entry["org_name"],
          location: entry["location"],
          website: entry["website"],
          learnMore: entry["/legal-aid/Amarillo"],
          zip: zipCode,
          phone: entry["contact"],
          assistance: entry["types_of_assistance"],
          image: entry["photo_link"],
          imagename: entry["org_name"] + " logo",
          id: entry["id"],
        };
        nearbyLegal.push(entryObj);
      });

      nearbyCommunitiesReq.map((entry) => {
        const entryObj = {
          communityName: entry["county_name"],
          unauthorizedPop: entry["unauthorized_population"],
          image: entry["photo_link"],
          imageTitle: entry["county_name"],
          id: entry["id"],
        };
        nearbyCommunities.push(entryObj);
      });
      name = res.data["name"];
      lat = res.data["latitude"];
      lng = res.data["longitude"];

      image = res.data["photo_link"];
      const arrOfStreetInfo = res.data["street"].split(" ");
      //console.log(arrOfStreetInfo);
      const cityAndState =
        arrOfStreetInfo[arrOfStreetInfo.length - 4] +
        ", " +
        arrOfStreetInfo[arrOfStreetInfo.length - 3];
      rows = [
        createData("Street Address: ", res.data["street"]),
        createData("City, State : ", cityAndState),
        createData("Zip Code : ", res.data["zip"]),
        createData("Phone : ", res.data["phone"]),
        createData(
          "Website : ",
          <a href={res.data["website"]} target="_blank">
            {res.data["website"]}
          </a>,
        ),
      ];
      setLoaded(true);
    } catch (e) {
      console.log("could not fetch");
    }
  };

  return (
    <>
      {loaded ? (
        <>
          {" "}
          <Typography
            variant="h3"
            sx={{ textAlign: "center", padding: "10px" }}
          >
            {name}
          </Typography>
          <Box sx={{ justifyContent: "center" }}>
            <img
              src={image}
              alt={name + "logo"}
              width={"20%"}
              style={{
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            />
          </Box>
          <Stack
            sx={{
              maxWidth: { xs: "90%", md: "70%" },
              margin: "auto",
              paddingTop: "20px",
            }}
          >
            {rows.map((row) => (
              <StyledTableRow key={row.categoryName}>
                <StyledTableCell component="th" scope="row">
                  {row.categoryName}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {row.information}
                </StyledTableCell>
              </StyledTableRow>
            ))}

            {/* Map instance */}
            <Box sx={{ paddingTop: "20px" }}>
              <Map lat={lat} lng={lng} />
            </Box>
          </Stack>
          {/* Nearby communities and employment centers instances/cards */}
          <Box sx={{ paddingTop: 3 }} />
          <h2
            style={{
              paddingLeft: "20px",
              paddingRight: "20px",
              textAlign: "center",
            }}
          >
            Legal Aid Near This Center
          </h2>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: {
                xs: "repeat(2, 1fr)",
                md: "repeat(4, 1fr)",
              },
              columnGap: "20px",
              paddingLeft: "20px",
              paddingRight: "20px",
            }}
          >
            {nearbyLegal.map((center) => (
              <LegalCard center={center} key={nearbyLegal.id}/>
            ))}
          </Box>
          {/* Nearby communities and employment centers instances/cards */}
          <Box
            sx={{ paddingTop: 5, paddingLeft: "20px", paddingRight: "20px" }}
          />
          <h2
            style={{
              paddingLeft: "20px",
              paddingRight: "20px",
              textAlign: "center",
            }}
          >
            Communities Near This Center
          </h2>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: {
                xs: "repeat(2, 1fr)",
                md: "repeat(4, 1fr)",
              },
              columnGap: "20px",
              paddingLeft: "20px",
              paddingRight: "20px",
            }}
          >
            {nearbyCommunities.map((community) => (
              <CommunityCard community={community} key={community.id}/>
            ))}
          </Box>
        </>
      ) : (
        <>
          <Typography>Loading...</Typography>
        </>
      )}
    </>
  );
};

export default EmploymentInfo;
