import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import axios from "axios";
import { useState, useEffect } from "react";
import EmploymentCard from "./employInfoPages/EmploymentCard";
import { FormControl, Menu, Pagination } from "@mui/material";
import { Stack } from "@mui/material";
import SortingAndFiltering from "./SortingAndFiltering";
import Button from "@mui/material/Button";
import { useNavigate, useParams } from "react-router-dom";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import SplitButton from "./FilterDropdown";
import FilterDropdown from "./FilterDropdown";

var employmentInfo = [
  {
    name: "Allied Employer Group",
    location: "Abilene, TX",
    website: "http://coemployer.com",
    learnMore: "/employment-assistance/AlliedEmployerGroup",
    image:
      "https://media.licdn.com/dms/image/C510BAQHXn_mJnmDFHw/company-logo_200_200/0/1519912768570?e=2147483647&v=beta&t=HTTFclNlOK7bpCUoN-pcx1zFXZk2Ql6pkXpitFA43Q0",
    imageTitle: "Allied Employer Group Logo",
    employmentId: 1,
  },
  {
    name: "CareerOneStop",
    location: "Abilene, TX",
    website: "http://www.careeronestop.org",
    learnMore: "/employment-assistance/CareerOneStop",
    image:
      "https://www.careeronestop.org/TridionMultimedia/careeronestoplogo_tcm24-129.png",
    imageTitle: "CareerOneStop Logo",
    employmentId: 1,
  },
  {
    name: "Work Services Corp",
    location: "Wichita Falls, TX ",
    website: "http://www.workservicescorp.com",
    learnMore: "/employment-assistance/WorkServicesCorp",
    image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQgUljnm8Jlv2t9GV9mAOwqeKaYjBYB-uX4w1aS5FlygTq6PxiGA7SZtG7aqPWg0niy45o&usqp=CAU",
    imageTitle: "Work Services Corp Logo",
    employmentId: 1,
  },
];


var sortAttributes = ["name", "zip"];
var currentInfo = [];

const Employment = () => {
  const [loaded, setLoaded] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  var [sortType, setSortType] = React.useState("ascending");
  var [currentAttribute, setCurrentAttribute] = React.useState("");
  var [filterType, setFilterType] = React.useState("");
  var [targetValue, setTargetValue] = React.useState("");
  var [resetPressed, setResetPressed] = React.useState(false);
  var [result, setResult] = React.useState("");
  var [keyword, setKeyword] = React.useState("");


  const handlePaginationChange = (event, value) => {
    setCurrentPage(value);
    currentPage * 8 >= employmentInfo.length
      ? (currentInfo = employmentInfo.slice((value - 1) * 8, value * 8))
      : (currentInfo = employmentInfo.slice((value - 1) * 8, value * 8));
  };
  useEffect(() => {
    findAllEmploymentCenters(
      setLoaded,
      sortType,
      currentAttribute,
      filterType, 
      targetValue, 
      resetPressed,
      result
    );
  }, [sortType, currentAttribute, filterType, targetValue, resetPressed, result]);



  const generateURL = (sortType, currentAttribute, filterType, targetValue, resetPressed, result) => {
    var url = "https://api.undocumentedtexasimmigrants.me/employment"; 

    if (resetPressed) {
      console.log("reset pressed2")
      setResetPressed(false);
      setCurrentAttribute("");
      setSortType("ascending");
      setKeyword("");
      return url 
    }

    if (result != ""){
      console.log("generating URL, result is: "+result);
      url = "https://api.undocumentedtexasimmigrants.me/search/employment?id=" + result;
      setKeyword(result);
      setResult("");
      return url;
    }

    //set the sortType
    if(sortType != ""){
      if(sortType == "ascending"){
        sortType = "asc"
      } else if(sortType == "descending"){
        sortType = "desc"
      }
      url = url + "?sort=" + currentAttribute + "&order=" + sortType;
    } 

    if(sortType == ""){
      url = url + "?sort=" + currentAttribute + "&order=asc";
    }

    if(filterType != ""){
      url = "https://api.undocumentedtexasimmigrants.me/employment"; 
      if(filterType == "filterByZipcode"){
        filterType = "zipcode";
        setCurrentAttribute("");
      } else if(filterType == "filterByPhone"){
        filterType = "phone";
        setCurrentAttribute("");
      } else if (filterType == "filterByWebsite"){
        filterType = "website";
        setCurrentAttribute("");
        url = url + "?filter=website";
        setFilterType("");
        setTargetValue ("");
        return url
      }

      url = url + "?filter=" + filterType + "&value=" + targetValue;
      setFilterType("");
      setTargetValue("");
    }

    return url 
  }


  const findAllEmploymentCenters = async (setLoaded, sortType, currentAttribute, filterType, targetValue, resetPressed, result) => {
    try {
      setLoaded(false);
      var url = generateURL(sortType, currentAttribute, filterType, targetValue, resetPressed, result);

      const res = await axios.get(url);
      employmentInfo = [];
      res.data.map((entry) => {
        const entryObj = {
          name: entry["name"],
          location: entry["street"],
          website: entry["website"],
          learnMore: "/employment-assistance/AlliedEmployerGroup",
          image: entry["photo_link"],
          imageTitle: entry["name"] + " Logo",
          id: entry["id"],
        };
        employmentInfo.push(entryObj);
      });
      currentInfo = employmentInfo.slice(0, 8);
      setLoaded(true);
      setFilterType("");
    } catch (e) {
      console.log("Could not fetch: " + e);
    }
  };

  var zipOptions = ['75137', '75149', '75670','75693', '75801', '75901', '75904', '76020', '76040', '76092', '76137', '76180', '77385', '77469', '77521', '77531', '77547', '77550', '77591' ];

  var phoneOptions = ['325', '281', '979', '806', '432', '817', '361', '302', '940', '214', '830', '512', '832', '877', '254', '713'];

  return (
    <>
      {loaded ? (
        <>
          <SortingAndFiltering 
            model="/employment-assistance/"
            sortAttributes={sortAttributes} 
            sortType={sortType} 
            setSortType={setSortType}
            currentAttribute={currentAttribute}
            setCurrentAttribute={setCurrentAttribute}
            setResult={setResult} />

          <Box textAlign='center'>
            <FilterDropdown 
            name="Zipcode" 
            options = {zipOptions}
            model="/employment-assistance/"
            filterType="filterByZipcode"
            setFilterType={setFilterType}
            targetValue={targetValue}
            setTargetValue={setTargetValue}
            />

            <FilterDropdown 
            name="Area Code" 
            options = {phoneOptions} 
            model="/employment-assistance/"
            filterType="filterByPhone"
            setFilterType={setFilterType}
            targetValue={targetValue}
            setTargetValue={setTargetValue}
            />

            <FilterDropdown
            name="Website"
            options={["Has Website Available"]}
            model="/employment-assistance/"
            filterType="filterByWebsite"
            setFilterType={setFilterType}
            targetValue={targetValue}
            setTargetValue={setTargetValue}
            />
          </Box>
          
          <Box textAlign='center'>
            <Button variant="contained" sx={{ marginLeft: "10px" }}
            onClick={() => {
              setResetPressed(true);
              console.log("reset pressed");
            }}
            >
              Reset
            </Button>
          </Box>

          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: {
                xs: "repeat(2, 1fr)",
                md: "repeat(4, 1fr)",
              },
              columnGap: "20px",
            }}
          >
            {currentInfo.map((employment) => (
              <EmploymentCard employment={employment} key={employment.id} wordToHighlight={keyword}/>
            ))}
          </Box>
          <Stack alignItems="center" sx={{ paddingTop: "20px" }}>
            <Pagination
              size="large"
              count={Math.ceil(employmentInfo.length / 8)}
              page={currentPage}
              onChange={handlePaginationChange}
            />
          </Stack>
          <Typography variant="h6" sx={{ padding: "5px" }}>
            Instances: {employmentInfo.length}
          </Typography>
        </>
      ) : (
        <Typography variant="h3">Loading... </Typography>
      )}
    </>
  );
};

export default Employment;
