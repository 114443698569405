import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useEffect } from "react";
import Map from "../../maps/index";
import EmploymentCard from "../employInfoPages/EmploymentCard";
import LegalCard from "../legalInfoPages/LegalCard";

function createData(categoryName, estimate, percentage, bold) {
  return { categoryName, estimate, percentage };
}

var nearbyLegal = [];
var nearbyEmployment = [];
var rows = [];
var communityName = "";
var lat = 0;
var lng = 0;

const CommunityInfo = () => {
  //Get CommunityInfo for this page
  useEffect(() => {
    getInfo();
  }, []);

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const [loaded, setLoaded] = React.useState(false);

  const { id } = useParams();

  const getInfo = async () => {
    try {
      nearbyLegal = [];
      nearbyEmployment = [];
      setLoaded(false);
      const res = await axios.get(
        "https://api.undocumentedtexasimmigrants.me/community?id=" + id,
      );

      const nearbyRes = await axios.get(
        "https://api.undocumentedtexasimmigrants.me/community/nearby?id=" + id,
      );

      const nearbyLegalReq = nearbyRes.data["nearby_legalAids"];
      const nearbyEmploymentReq = nearbyRes.data["nearby_employments"];

      nearbyLegalReq.map((entry) => {
        const locArr = entry["location"].split(" ");
        const zipCode = locArr[locArr.length - 1];
        const entryObj = {
          name: entry["org_name"],
          location: entry["location"],
          website: entry["website"],
          learnMore: entry["/legal-aid/Amarillo"],
          zip: zipCode,
          phone: entry["contact"],
          assistance: entry["types_of_assistance"],
          image: entry["photo_link"],
          imagename: entry["org_name"] + " logo",
          id: entry["id"],
        };
        nearbyLegal.push(entryObj);
      });

      nearbyEmploymentReq.map((entry) => {
        const entryObj = {
          name: entry["name"],
          location: entry["street"],
          website: entry["website"],
          learnMore: "/employment-assistance/AlliedEmployerGroup",
          image: entry["photo_link"],
          imageTitle: entry["name"] + " Logo",
          id: entry["id"],
        };
        nearbyEmployment.push(entryObj);
      });
      //console.log(nearbyEmployment);
      communityName = res.data["county_name"];

      lat = res.data["latitude"];
      lng = res.data["longitude"];

      const ageCatArr = res.data["age_categories"].split(",");
      const ageNumArr = res.data["age_numbers"].split(",");
      const countriesOfBirthCatArr =
        res.data["countries_of_birth_categories"].split(",");
      const countriesOfBirthNumArr =
        res.data["countries_of_birth_numbers"].split(",");
      const yearsOfUSResidenceCatArr =
        res.data["years_of_us_residence_categories"].split(",");
      const yearsOfUSResidenceNumArr =
        res.data["years_of_us_residence_numbers"].split(",");

      rows.push({
        categoryName: "Unauthorized Population",
        estimate: res.data["unauthorized_population"],
        percentage: "",
      });
      rows.push(createData(<b>Top Countries of Birth</b>));
      ageCatArr.forEach((cat, i) =>
        rows.push({
          categoryName: cat,
          estimate: ageNumArr[i],
          percentage:
            (
              (ageNumArr[i] / res.data["unauthorized_population"]) *
              100
            ).toFixed(2) + "%",
        }),
      );
      rows.push(createData(<b>Regions of Birth</b>));
      countriesOfBirthCatArr.forEach((cat, i) =>
        rows.push({
          categoryName: cat,
          estimate: countriesOfBirthNumArr[i],
          percentage:
            (
              (countriesOfBirthNumArr[i] /
                res.data["unauthorized_population"]) *
              100
            ).toFixed(2) + "%",
        }),
      );
      rows.push(createData(<b>Years of U.S. Residence</b>));
      yearsOfUSResidenceCatArr.forEach((cat, i) =>
        rows.push({
          categoryName: cat,
          estimate: yearsOfUSResidenceNumArr[i],
          percentage:
            (
              (yearsOfUSResidenceNumArr[i] /
                res.data["unauthorized_population"]) *
              100
            ).toFixed(2) + "%",
        }),
      );
      //console.log(rows);
      setLoaded(true);
    } catch (e) {
      console.log("could not fetch: " + e);
    }
  };

  return (
    <>
      {loaded ? (
        <>
          <Typography
            variant="h3"
            sx={{ textAlign: "center", padding: "10px" }}
          >
            Profile of the unauthorized population: {communityName}
          </Typography>
          <Box
            sx={{
              justifyItems: "center",
              maxWidth: { xs: "90%", md: "70%" },
              margin: "auto",
            }}
          >
            <TableContainer component={Paper} style={{}}>
              <Table sx={{ minWidth: 500 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Demographics</StyledTableCell>
                    <StyledTableCell align="right">Estimate</StyledTableCell>
                    <StyledTableCell align="right">% of Total</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <StyledTableRow key={row.categoryName}>
                      <StyledTableCell component="th" scope="row">
                        {row.categoryName}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {row.estimate ?? "100"}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {row.percentage ?? ""}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Box sx={{ paddingTop: "20px" }}>
              <Map lat={lat} lng={lng} />
            </Box>
          </Box>

          {/* Nearby communities and employment centers instances/cards */}
          <Box sx={{ paddingTop: 3 }} />
          <h2
            style={{
              paddingLeft: "20px",
              paddingRight: "20px",
              textAlign: "center",
            }}
          >
            Legal Aid Near This Center
          </h2>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: {
                xs: "repeat(2, 1fr)",
                md: "repeat(4, 1fr)",
              },
              columnGap: "20px",
              paddingLeft: "20px",
              paddingRight: "20px",
            }}
          >
            {nearbyLegal.map((center) => (
              <LegalCard center={center} key={center.id} />
            ))}
          </Box>

          <Box sx={{ paddingTop: 3 }} />
          <h2
            style={{
              paddingLeft: "20px",
              paddingRight: "20px",
              textAlign: "center",
            }}
          >
            Employment Centers Near This Center
          </h2>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: {
                xs: "repeat(2, 1fr)",
                md: "repeat(4, 1fr)",
              },
              columnGap: "20px",
              paddingLeft: "20px",
              paddingRight: "20px",
            }}
          >
            {nearbyEmployment.map((employment) => (
              <EmploymentCard employment={employment} key={employment.id}/>
            ))}
          </Box>
        </>
      ) : (
        <>
          <Typography variant="h4">Loading...</Typography>
        </>
      )}
    </>
  );
};

export default CommunityInfo;
