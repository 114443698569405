import React, { useEffect, useState } from "react";
import axios from "axios";
import { Scatter } from "react-chartjs-2";

const ScatterPlot = () => {
  const [data, setData] = useState([]);

  useEffect(() => {


    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://backend.vetsaid.me/api/jobs?page[size]=100&page[number]=1",
          {
            headers: {
              Accept: "application/vnd.api+json",
            },
          }
        );
        setData(response.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  // Extracting pay_low_end and pay_high_end values for the scatter plot
const scatterData = {
    datasets: [
      {
        label: "Pay Range for ",
        data: data.map((item) => ({
          x: item.attributes.pay_low_end,
          y: item.attributes.pay_high_end,
          jobTitle: item.attributes.title,
        })),
        backgroundColor: "rgba(75,192,192,0.4)", // Color of the points
        pointRadius: 5, // Adjust the point radius to make dots bigger
      },
    ],
  };
  
  const scatterOptions = {
    scales: {
      x: {
        type: "linear",
        position: "bottom",
        title: {
          display: true,
          text: "Pay Low End",
        },
      },
      y: {
        type: "linear",
        position: "left",
        title: {
          display: true,
          text: "Pay High End",
        },
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            return `${tooltipItem.dataset.label} ${tooltipItem.dataset.data[tooltipItem.dataIndex].jobTitle}: Pay Low End - $${tooltipItem.dataset.data[tooltipItem.dataIndex].x}, Pay High End - $${tooltipItem.dataset.data[tooltipItem.dataIndex].y}`;
          },
        },
      },
    },
  };
    

  return (
    <div className="scatter-container">
      <Scatter data={scatterData} options={scatterOptions} />
    </div>
  );
};

export default ScatterPlot;
