import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useNavigate, useParams } from "react-router-dom";

export default function FilterDropdown(props) {
  const [variable, setVariable] = React.useState('');
  const navigate = useNavigate();

  const handleChange = (event) => {
    // console.log(event)
    setVariable(event.target.value);
    if(event.target.value == "None"){
      navigate(props.model);
      props.setFilterType("");
      props.setTargetValue("");
      // console.log("filter dropdown, event.target.value is:" + event.target.value);
      // console.log("filter dropdown, props.filterType is:" + props.filterType);
    } else {
      navigate(props.model + props.filterType + "/" + event.target.value)
      // console.log("filter dropdown, event.target.value is:" + event.target.value);
      // console.log("filter dropdown, props.filterType is:" + props.filterType);

      //set the filter type and target value in the parent component
      props.setFilterType(props.filterType);
      props.setTargetValue(event.target.value);
    }
  };

  return (
    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
      <InputLabel>{props.name}</InputLabel>
      <Select
        value={variable}
        label= {props.name}
        onChange={handleChange}
      >
        {props.options.map((option) => (
          <MenuItem value={option}>{option}</MenuItem>
        ))}
        
      </Select>
    </FormControl>
  );
}