import { useTheme } from "@mui/material/styles";
import { Typography, useMediaQuery } from "@mui/material";
import {
  BannerContainer,
  BannerContent,
  BannerDescription,
  BannerTitle,
  BannerImage,
} from "../../../styles/banner";
import splashimage from "../../../assets/splashpageImages/flags.png";

export default function Banner() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <BannerContainer>
      <BannerImage src={splashimage} />

      <BannerContent>
        <BannerTitle variant="h1">Undocumented Texas Immigrants</BannerTitle>

        <BannerDescription variant="subtitle">
          Find Support, Information, and Resourses Here!
        </BannerDescription>
      </BannerContent>
    </BannerContainer>
  );
}
