import React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { SearchBar } from "./SearchBar";



const SortingAndFiltering = (props) => {
  
  const [anchorEl, setAnchorEl] = React.useState(null);
  const navigate = useNavigate();
  const open = Boolean(anchorEl);
  // const [result, setResult] = useState("");

  // const sortAttributes = props.sortAttr;

  //var newUrl = props.origUrl; // console output defines props.origUrl as undefined
  // var newUrl = props;
  //var newUrl = "https://api.undocumentedtexasimmigrants.me/community";

  const handleMenuClick = (menuItem) => {
    //setAnchorEl(null);
    // console.log("handleMenuClick, menuItem is:" + menuItem);
    // console.log(menuItem);
    if(props.currentAttribute == menuItem) {
      // close menu 
      setAnchorEl(null);
    }
    props.setCurrentAttribute(menuItem);
    // console.log("navigating to: " + props.model + menuItem + "/" + props.sortType);
    navigate(props.model + menuItem + "/" + props.sortType);
  };

  // const handleSearch = (value) => {

  // };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    //close the menu 
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleSortChange = (event, newSortType) => {
    props.setSortType(newSortType);
    if(props.currentAttribute == "") {
      navigate(props.model + props.sortAttributes[0] + "/" +newSortType);
    }else{
      navigate(props.model + props.currentAttribute +  "/" + newSortType); //fixed this to just have the current Attribute instead of the whole url cuz it was being repeated before
    }

  };
  const returnAttribute = () => {
    return props.currentAttribute;
  };

  const getName = (attribute) => {
    console.log("attribute is: " + attribute)
    if(attribute == "org_name"){
      return "Organization Name";
    } else if (attribute == "zipcode" || attribute == "zip"){
      return "Zip Code";
    } else if (attribute == "location"){
      return "Address";
    } else if (attribute == "name"){
      return "Employment Center Name";
    } 

    return attribute;
  }
  
  return (
    <>
      <Box
        sx={{
          textAlign: "center",
          padding: "10px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        {props.removeSearch === undefined && (
          <>
            <SearchBar setResult={props.setResult}/>
            {/* <TextField id="outlined-basic" label="Search" variant="outlined" />
            <Button variant="contained" sx={{ marginLeft: "10px" }} onClick={handleSearch}>
              Search
            </Button> */}
          </>
        )}

        <Box sx={{ padding: "10px" }} />
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Button
          id="basic-button"
          variant="outlined"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >
          Sort By: {returnAttribute() && <>&nbsp;</>} <u style={{ color: 'grey' }}>{returnAttribute()}</u>
        </Button>

        <Box sx={{ padding: "10px" }} />
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          {props.sortAttributes.map((attribute) => (
            <MenuItem
              key={attribute}
              onClick={() => handleMenuClick(attribute)}
            >
              {getName(attribute)}
            </MenuItem>
            
          ))}
        </Menu>

        
        <Box sx={{ padding: "10px" }} />

        <ToggleButtonGroup
          value={props.sortType}
          exclusive
          onChange={handleSortChange}
          aria-label="sort"
        >
          <ToggleButton value="ascending" aria-label="asc">
            Ascending
          </ToggleButton>
          <ToggleButton value="descending" aria-label="desc">
            Descending
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>
    </>
  );
};

export default SortingAndFiltering;
