import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Button,
  CardActions,
} from "@mui/material";
import Highlighter from "react-highlight-words";
import { Link } from "react-router-dom";

const CommunityCard = (props) => {
  const { community } = props;
  const highlightText = (input) => {
    if (props.wordToHighlight != null) {
      return (
        <Highlighter
          highlightClassName="HighlightText"
          searchWords={[props.wordToHighlight]}
          autoEscape={true}
          textToHighlight={input}
        />
      );
    }
    return input;
  };
  return (
    <Box
      sx={{
        justifySelf: "center",
        paddingTop: "20px",
        width: { xs: "40vw", md: "23.5vw" },
        height: "100%",
      }}
      key={community.id}
    >
      <Card sx={{}}>
        <CardMedia
          sx={{ height: 180 }}
          image={community.image}
          title={community.imageTitle}
        />
        <CardContent>
          <Typography
            variant="h6"
            color="text.secondary"
            style={{
              textAlign: "center",
              fontsize: "100%",
              fontWeight: "bold",
            }}
          >
            {highlightText(community.communityName)}
          </Typography>
          <Typography variant="body1">
            Unauthorized Population : {community.unauthorizedPop}
          </Typography>
        </CardContent>
        <CardActions>
          <Link to={"/communities/" + community.id}>
            <Button size="small" target="_blank">
              Learn More
            </Button>
          </Link>
        </CardActions>
      </Card>
    </Box>
  );
};

export default CommunityCard;
