import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import * as Plot from "@observablehq/plot";
import "./GraphStyle.css";

export default function HousingMap() {
  const [housingData, setHousingData] = useState([]);
  const plotRef = useRef();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://backend.vetsaid.me/api/housing?page[size]=100&page[number]=1",
          {
            headers: {
              Accept: "application/vnd.api+json",
            },
          }
        );
        setHousingData(response.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (!plotRef.current || housingData.length === 0) return;

    const graph = Plot.plot({
      color: { scheme: "cool", legend: true },
      x: { label: "Longitude", grid: true },
      y: { label: "Latitude", grid: true },
      marks: [
        Plot.dot(housingData, {
          x: d => d.attributes.longitude,
          y: d => d.attributes.latitude,
          size: 10, // Adjust the size value as needed (larger size)
          stroke: d => d.attributes.total_units,
          //fill: "#3182bd",
          fill: d => d.attributes.total_units,
          tip: true,
        }),
      ],
    });

    plotRef.current.innerHTML = "";
    plotRef.current.append(graph);

    return () => graph.remove();
  }, [housingData]);

  return <div ref={plotRef} className="housingMap" />;
}
