import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';
import "./Components.css";

const BarChart = () => {
  const chartRef = useRef(null);

  const fetchData = async (link) => {
    try {
      const response = await fetch(link);
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching data:', error);
      return null;
    }
  };

  const generateChart = async () => {
    try {
      const data = await fetchData('https://api.undocumentedtexasimmigrants.me/community');
  
      if (!data) {
        return;
      }
  
      for (let i = 0; i < data.length; i++) {
        data[i]["numEmploy"] = 0;
      }
  
      console.log(data);
  
      const employ = await fetchData('https://api.undocumentedtexasimmigrants.me/employment');
  
      console.log(employ);
  
      for (let i = 0; i < employ.length; i++) {
        let val = employ[i]["community_id"] - 1;
        data[val]["numEmploy"]++;
      }
  
      // Extract relevant data for the chart
      const categories = data.map(item => item.county_name);
      console.log(categories);
      const numbers = data.map(item => item.numEmploy);
  
      // Check if there's an existing Chart instance and destroy it
      if (chartRef.current) {
        chartRef.current.destroy();
      }
  
      // Create the chart as a horizontal bar chart
      const ctx = document.getElementById('bar').getContext('2d');
      const bar = new Chart(ctx, {
        type: 'bar',
        data: {
          labels: categories,
          datasets: [{
            data: numbers,
            backgroundColor: getColorArray(numbers.length),
          }],
        },
        options: {
          indexAxis: 'y',
          scales: {
            x: {
              beginAtZero: true,
            },
            y: {
              beginAtZero: true,
            },
          },
          plugins: {
            legend: {
              display: false,
            },
          },
          responsive: true,
          maintainAspectRatio: false,
        },
      });
  
      // Set the chart instance to the ref
      chartRef.current = bar;
    } catch (error) {
      console.error('Error generating chart:', error);
    }
  };
  

  const getColorArray = (length) => {
    const colors = ['#FF5733', '#33FF57', '#5733FF', '#FF3357', '#FF5733'];
    const colorArray = [];
    for (let i = 0; i < length; i++) {
      colorArray.push(colors[i % colors.length]);
    }
    return colorArray;
  };

  useEffect(() => {
    generateChart();
  
    // Cleanup function to destroy the chart when the component unmounts
    return () => {
      if (chartRef.current) {
        chartRef.current.destroy();
      }
    };
  }, []); // Run once when the component mounts
  
  const chartContainerStyle = {
    height: '400px', // Adjust the height as needed
    width: '70%',
    margin: 'auto',
  };

  return (
    <div className="bar" style={chartContainerStyle}>
    <canvas id="bar"></canvas>
    </div>
  );
};

export default BarChart;

