import { Box } from "@mui/material";
import { Colors } from "../../theme";
import styled from "@emotion/styled";

export const Splashcard = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  padding: "20px",
  [theme.breakpoints.up("md")]: {
    position: "relative",
  },
}));

export const CardImage = styled("img")(({ src, theme }) => ({
  src: `url(${src})`,
  width: "100%",
  height: "250px",
  objectFit: "cover",
  background: Colors.background,
  padding: "10px",
  [theme.breakpoints.down("md")]: {
    width: "100%",
    padding: "10px",
  },
}));

export const CardContentWrapper = styled(Box)(({ theme }) => ({
  padding: 8,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}));
